import React, { useState, useEffect, useCallback } from "react";
import * as Yup from "yup";
import * as actions from "store/actions/index.js";
//services
import { updateData_ } from "store/services/commonService";
import moment from "moment";
import { connect } from "react-redux";

const validationSchema = Yup.object().shape({
  // date_of_birth: Yup.string().required("Required"),
  // date_of_expiry: Yup.string().required("Required"),
  // document_numbers: Yup.string().required("Required"),
  // gender: Yup.string().required("Required"),
  // issuing_country: Yup.string().required("Required"),
  // last_name: Yup.string().required("Required"),
  // nationality: Yup.string().required("Required"),
  // police_record: Yup.string().required("Required"),
  // address_identity: Yup.string().required("Required"),
  // address_breakdown: Yup.string().required("Required"),
  // date_of_birth_identity: Yup.string().required("Required"),
  // date_of_birth_identity_breakdown: Yup.string().required("Required"),
  // external_created_at: Yup.string().required("Required"),
  // address_kyc: Yup.string().required("Required"),
  // applicant_name: Yup.string().required("Required"),
  // country_of_residency: Yup.string().required("Required"),
  // date_of_birth_kyc: Yup.string().required("Required"),
  // nationality_kyc: Yup.string().required("Required"),
  // postal_code: Yup.string().required("Required"),
});

const validateData = async (data) => {
  try {
    await validationSchema.validate(data, { abortEarly: false });
    return { isValid: true, errors: {} };
  } catch (error) {
    const errors = {};
    error.inner.forEach((err) => {
      errors[err.path] = err.message;
    });
    return { isValid: false, errors };
  }
};

const OrderDataInfo = ({
  setisUpdateProfile,
  editData,
  dispatch,
  userProfile,
}) => {
  const [show, setShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  // let userData =
  //   user?.permissions && user?.permissions?.length > 0
  //     ? JSON.parse(user?.permissions)
  //     : userPermission;
  // let userRole = userProfile ? userProfile?.role : user?.role;

  console.log(userPermission, "userProfile");

  const [fields, setfields] = useState({
    //new fields
    result: "",
    status: "",
    document_type: "",
    age_validation: "",
    data_comparison: "",
    data_comparison_breakdown: "",
    image_integrity: "",
    // image_integrity_breakdown
    colour_picture: "",
    conclusive_document_quality: "",
    image_quality: "",
    supported_document: "",
    // visual_authenticity
    digital_tampering: "",
    face_detection: "",
    fonts: "",
    original_document_present: "",
    picture_face_integrity: "",
    security_features: "",
    template: "",
    // kyc
    kyc_email_address: "",
    kyc_nationality: "",
    kyc_id_number: "",
    kyc_date_of_birth: "",
    kyc_address: "",
    kyc_postal_code: "",
    kyc_kyc_completed_at: "",
    kyc_ip_address: "",
    kyc_check_initiated_at: "",
    kyc_check_completed_at: "",
    kyc_approval_status: "",
    kyc_approved_by: "",
    kyc_approved_reason: "",
    kyc_approved_at: "",
    kyc_address_kyc: "",
    // photo
    photo_result: "",
    photo_status: "",
    photo_face_comparison: "",
    photo_image_integrity: "",
    photo_visual_authenticity: "",
    //watch list
    watchlist_result: "",
    watchlist_status: "",
    watchlist_politically_exposed_person: "",
    watchlist_sanction: "",
    watchlist_adverse_media: "",
    watchlist_monitored_lists: "",

    // old

    date_of_birth: "",
    date_of_expiry: "",
    document_numbers: "",
    gender: "",
    issuing_country: "",
    last_name: "",
    nationality: "",
    police_record: "",
    applicant_name: "",
    country_of_residency: "",
    date_of_birth_kyc: "",
    nationality_kyc: "",
    postal_code: "",
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );
  //commission function for particular user
  const CommissionParticularFunction = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let alpacaInfo = editData?.applicantData;
        // let current_date_time = moment().format("YYYY-MM-DD HH:mm:ssZ");
        // let first_name = alpacaInfo?.info?.firstName || editData?.first_name;
        // let last_name = alpacaInfo?.info?.lastName || editData?.last_name;
        let cipInfo = {
          provider_name: ["sumsub"],
          kyc: {
            id: alpacaInfo.id, //info?.idDocs[0]?.number || "N/A",
            email_address: fields?.kyc_email_address || "",
            nationality: fields?.kyc_nationality || "",
            id_number: fields?.kyc_id_number || "",
            date_of_birth: fields?.kyc_date_of_birth || "",
            address: fields?.kyc_address || "",
            postal_code: fields?.kyc_postal_code || "",
            kyc_completed_at:
              moment(fields?.kyc_kyc_completed_at).format(
                "YYYY-MM-DDTHH:mm:ssZ"
              ) || "",
            ip_address: fields?.kyc_ip_address || "",
            check_initiated_at:
              moment(fields?.kyc_check_initiated_at).format(
                "YYYY-MM-DDTHH:mm:ssZ"
              ) || "",
            check_completed_at:
              moment(fields?.kyc_check_completed_at).format(
                "YYYY-MM-DDTHH:mm:ssZ"
              ) || "",
            approval_status: fields?.kyc_approval_status || "",
            approved_by: fields?.kyc_approved_by || "",
            approved_reason: fields?.kyc_approved_reason || "",
            approved_at:
              moment(fields?.kyc_approved_at).format("YYYY-MM-DDTHH:mm:ssZ") ||
              "",
            address_kyc: fields?.kyc_address_kyc || "",
            date_of_birth_kyc: fields?.date_of_birth_kyc || "",
            nationality_kyc: fields?.nationality_kyc || "",
            postal_code: fields?.nationality_kyc || "",
            country_of_residency: fields?.country_of_residency || "",
            // applicant_name: first_name + " " + last_name,
            // email_address: editData?.email,
            // nationality: alpacaInfo?.info?.country || editData?.nationality,
            // id_number: alpacaInfo.info?.idDocs[0]?.number || "N/A",
            // date_of_birth: moment(
            //   alpacaInfo?.info?.idDocs[0]?.dob || editData?.dob
            // ).format("YYYY-MM-DD"),
            // address:
            //   (alpacaInfo?.info?.addresses?.length &&
            //     alpacaInfo?.info?.addresses[0]?.street +
            //       alpacaInfo?.info?.addresses[0]?.town +
            //       alpacaInfo?.info?.addresses[0]?.state) ||
            //   editData?.address,
            // postal_code:
            //   (alpacaInfo?.info?.addresses?.length &&
            //     alpacaInfo?.info?.addresses[0]?.postCode) ||
            //   editData?.address,
            // country_of_residency:
            //   (alpacaInfo?.info?.addresses?.length &&
            //     alpacaInfo?.info?.addresses[0]?.county) ||
            //   editData?.county ||
            //   "N/A",
            // kyc_completed_at:
            //   moment(alpacaInfo?.review?.reviewDate).format(
            //     "YYYY-MM-DDTHH:mm:ssZ"
            //   ) || "N/A",
            // ip_address: "14.99.95.121",
            // check_initiated_at:
            //   moment(alpacaInfo?.review?.createDate).format(
            //     "YYYY-MM-DDTHH:mm:ssZ"
            //   ) || "N/A",
            // check_completed_at:
            //   moment(alpacaInfo?.review?.reviewDate).format(
            //     "YYYY-MM-DDTHH:mm:ssZ"
            //   ) || "N/A",
            // approval_status: "approved",
            // approved_by: "Ilham S Syarif", // needed
            // approved_reason: "all checks cleared",
            // approved_at: moment(current_date_time).format(
            //   "YYYY-MM-DDTHH:mm:ssZ"
            // ),
          },
          document: {
            // id: alpacaInfo?.review?.reviewId,
            // result: "clear",
            // status: "complete",
            // created_at:
            //   moment(alpacaInfo?.review?.createDate).format(
            //     "YYYY-MM-DDTHH:mm:ssZ"
            //   ) || "N/A",
            // first_name:
            //   alpacaInfo.info?.idDocs[0]?.firstName || editData?.first_name,
            // last_name:
            //   alpacaInfo.info?.idDocs[0]?.firstName || editData?.last_name,
            // gender: alpacaInfo.info?.gender || "N/A",
            // date_of_birth:
            //   moment(alpacaInfo?.info?.idDocs[0]?.dob).format("YYYY-MM-DD") ||
            //   editData?.dob,
            // date_of_expiry:
            //   moment(alpacaInfo?.info?.idDocs[0]?.validUntill).format(
            //     "YYYY-MM-DD"
            //   ) || "N/A",
            // issuing_country: alpacaInfo?.info?.idDocs[0]?.country || "N/A",
            // nationality: alpacaInfo?.info?.nationality || editData?.nationality,
            // document_numbers: alpacaInfo.info?.idDocs[0]?.number || "N/A",
            // document_type: alpacaInfo?.info?.idDocs[0]?.idDocType,
            // age_validation: "clear",
            // data_comparison: "clear",
            // data_comparison_breakdown: "clear",
            // image_integrity: "clear",

            //new fields
            result: fields?.result || "",
            status: fields?.status || "",
            document_type: fields?.document_type || "",
            age_validation: fields?.age_validation || "",
            data_comparison: fields?.data_comparison || "",
            data_comparison_breakdown: fields?.data_comparison_breakdown || "",
            image_integrity: fields?.image_integrity || "",
            date_of_birth: fields?.date_of_birth || "",
            date_of_expiry: fields?.date_of_expiry || "",
            document_numbers: fields?.document_numbers || "",
            gender: fields?.gender || "",
            issuing_country: fields?.issuing_country || "",
            last_name: fields?.last_name || "",
            nationality: fields?.nationality || "",

            image_integrity_breakdown: {
              colour_picture: fields?.colour_picture || "",
              conclusive_document_quality:
                fields?.conclusive_document_quality || "",
              image_quality: fields?.image_integrity || "",
              supported_document: fields?.supported_document || "",
            },
            visual_authenticity: {
              digital_tampering: fields?.digital_tampering || "",
              face_detection: fields?.face_detection || "",
              fonts: fields?.fonts || "",
              original_document_present:
                fields?.original_document_present || "",
              picture_face_integrity: fields?.picture_face_integrity || "",
              security_features: fields?.security_features || "",
              template: fields?.template || "",
            },
          },
          photo: {
            // id: alpacaInfo?.review?.reviewId,
            // result: "clear",
            // status: "complete", // needed
            // created_at:
            //   moment(alpacaInfo?.review?.createDate).format(
            //     "YYYY-MM-DDTHH:mm:ssZ"
            //   ) || "N/A",
            // face_comparison: "clear",
            // image_integrity: "clear",
            // visual_authenticity: "clear",
            result: fields?.photo_result || "",
            status: fields?.photo_status || "",
            face_comparison: fields?.photo_face_comparison || "",
            image_integrity: fields?.photo_image_integrity || "",
            visual_authenticity: fields?.photo_visual_authenticity || "",
          },
          watchlist: {
            id: alpacaInfo?.review?.reviewId,
            result: fields?.watchlist_result || "",
            status: fields?.watchlist_status || "",
            // created_at:
            //   moment(alpacaInfo?.review?.createDate).format(
            //     "YYYY-MM-DDTHH:mm:ssZ"
            //   ) || "N/A",
            politically_exposed_person:
              fields?.watchlist_politically_exposed_person || "",
            sanction: fields?.watchlist_sanction || "",
            adverse_media: fields?.watchlist_adverse_media || "",
            monitored_lists: fields?.watchlist_monitored_lists || "",
          },
        };

        console.log(fields, "Fields");

        let finalObj = {
          provider_name: ["sumsub"],
          document: {
            // ...editData?.applicantData?.document,
            ...cipInfo.document,
            date_of_birth: fields?.date_of_birth,
            document_numbers: Array.isArray(fields?.document_numbers)
              ? fields?.document_numbers
              : [fields?.document_numbers],
            gender: fields?.gender || "male",
            issuing_country: fields?.issuing_country,
            last_name: fields?.last_name,
            nationality: fields?.nationality,
            police_record: fields?.police_record,
            date_of_expiry: fields?.date_of_expiry,
          },
          kyc: {
            // ...editData?.applicantData?.kyc,
            ...cipInfo.kyc,
            address_kyc: fields?.kyc_address_kyc,
            applicant_name: fields?.applicant_name,
            country_of_residency: fields?.country_of_residency,
            date_of_birth_kyc: fields?.date_of_birth_kyc,
            date_of_birth: fields?.date_of_birth_kyc,
            nationality_kyc: fields?.nationality,
            postal_code: fields?.postal_code,
          },
          photo: {
            ...cipInfo.photo,
          },
          watchlist: {
            ...cipInfo.watchlist,
          },
        };
        let obj = {
          url: "update/cipInfo",
          userId: editData?._id,
          cipInfo: finalObj,
        };

        console.log(obj, "obj");
        setShow(true);
        await dispatch(updateData_(obj));
        setShow(false);
        setisUpdateProfile(true);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      console.log(err);
      setShow(false);
    }
  };
  useEffect(() => {
    if (!editData?.cipInfo && !editData?.applicantData) {
      return;
    }

    if (!editData?.cipInfo) {
      let alpacaInfo = editData?.applicantData;
      let current_date_time = moment().format("YYYY-MM-DD HH:mm:ssZ");
      let first_name = alpacaInfo?.info?.firstName || editData?.first_name;
      let last_name = alpacaInfo?.info?.lastName || editData?.last_name;
      let cipInfo = {
        provider_name: ["sumsub"],
        kyc: {
          id: alpacaInfo.id, //info?.idDocs[0]?.number || "N/A",
          applicant_name: first_name + " " + last_name,
          email_address: editData?.email,
          nationality: alpacaInfo?.info?.country || editData?.nationality,
          id_number: alpacaInfo.info?.idDocs[0]?.number || "N/A",
          date_of_birth: moment(
            alpacaInfo?.info?.idDocs[0]?.dob || editData?.dob
          ).format("YYYY-MM-DD"),
          address:
            (alpacaInfo?.info?.addresses?.length &&
              alpacaInfo?.info?.addresses[0]?.street +
                alpacaInfo?.info?.addresses[0]?.town +
                alpacaInfo?.info?.addresses[0]?.state) ||
            editData?.address,
          postal_code:
            (alpacaInfo?.info?.addresses?.length &&
              alpacaInfo?.info?.addresses[0]?.postCode) ||
            editData?.address,
          country_of_residency:
            (alpacaInfo?.info?.addresses?.length &&
              alpacaInfo?.info?.addresses[0]?.county) ||
            editData?.county ||
            "N/A",
          kyc_completed_at: alpacaInfo?.review?.reviewDate || "N/A",
          ip_address: "14.99.95.121",
          check_initiated_at: alpacaInfo?.review?.createDate || "N/A",
          check_completed_at: alpacaInfo?.review?.reviewDate || "N/A",
          approval_status: "approved",
          approved_by: "Ilham S Syarif", // needed
          approved_reason: "all checks cleared",
          approved_at: current_date_time,
        },
        document: {
          id: alpacaInfo?.review?.reviewId,
          result: "clear",
          status: "complete",
          created_at: alpacaInfo?.review?.createDate || "N/A",
          first_name:
            alpacaInfo.info?.idDocs[0]?.firstName || editData?.first_name,
          last_name:
            alpacaInfo.info?.idDocs[0]?.firstName || editData?.last_name,
          gender: alpacaInfo.info?.gender || "male",
          date_of_birth:
            moment(alpacaInfo?.info?.idDocs[0]?.dob).format("YYYY-MM-DD") ||
            editData?.dob,
          date_of_expiry:
            moment(alpacaInfo?.info?.idDocs[0]?.validUntill).format(
              "YYYY-MM-DD"
            ) || "N/A",
          issuing_country: alpacaInfo?.info?.idDocs[0]?.country || "N/A",
          nationality: alpacaInfo?.info?.nationality || editData?.nationality,
          document_numbers: alpacaInfo.info?.idDocs[0]?.number || "N/A",
          document_type: alpacaInfo?.info?.idDocs[0]?.idDocType,
          age_validation: "clear",
          data_comparison: "clear",
          data_comparison_breakdown: "clear",
          image_integrity: "clear",
          image_integrity_breakdown: {
            colour_picture: "clear",
            conclusive_document_quality: "clear",
            image_quality: "clear",
            supported_document: "clear",
          },
          visual_authenticity: {
            digital_tampering: "clear",
            face_detection: "clear",
            fonts: "clear",
            original_document_present: "clear",
            picture_face_integrity: "clear",
            security_features: "clear",
            template: "clear",
          },
          police_record: "test",
        },
        photo: {
          id: alpacaInfo?.review?.reviewId,
          result: "clear",
          status: "complete", // needed
          created_at: alpacaInfo?.review?.createDate || "N/A",
          face_comparison: "clear",
          image_integrity: "clear",
          visual_authenticity: "clear",
        },
        watchlist: {
          id: alpacaInfo?.review?.reviewId,
          result: "clear",
          status: "complete",
          created_at: alpacaInfo?.review?.createDate || "N/A",
          politically_exposed_person: "clear",
          sanction: "clear",
          adverse_media: "clear",
          monitored_lists: "clear",
        },
      };

      editData.cipInfo = cipInfo;

      setfields((p) => ({
        //new fields
        result: "",
        status: "",
        document_type: "",
        age_validation: "",
        data_comparison: "",
        data_comparison_breakdown: "",
        image_integrity: "",
        // image_integrity_breakdown
        colour_picture: "",
        conclusive_document_quality: "",
        image_quality: "",
        supported_document: "",
        // visual_authenticity
        digital_tampering: "",
        face_detection: "",
        fonts: "",
        original_document_present: "",
        picture_face_integrity: "",
        security_features: "",
        template: "",
        // kyc
        kyc_email_address: "",
        kyc_nationality: "",
        kyc_id_number: "",
        kyc_date_of_birth: "",
        kyc_address: "",
        kyc_postal_code: "",
        kyc_kyc_completed_at: "",
        kyc_ip_address: "",
        kyc_check_initiated_at: "",
        kyc_check_completed_at: "",
        kyc_approval_status: "",
        kyc_approved_by: "",
        kyc_approved_reason: "",
        kyc_approved_at: "",
        kyc_address_kyc: "",
        // photo
        photo_result: "",
        photo_status: "",
        photo_face_comparison: "",
        photo_image_integrity: "",
        photo_visual_authenticity: "",
        //watch list
        watchlist_result: "",
        watchlist_status: "",
        watchlist_politically_exposed_person: "",
        watchlist_sanction: "",
        watchlist_adverse_media: "",
        watchlist_monitored_lists: "",
        // old
        date_of_birth: editData?.cipInfo?.document?.date_of_birth,
        date_of_expiry: editData?.cipInfo?.document?.date_of_expiry,
        document_numbers: editData?.cipInfo?.document?.document_numbers,
        gender: editData?.cipInfo?.document?.gender,
        issuing_country: editData?.cipInfo?.document?.issuing_country,
        last_name: editData?.cipInfo?.document?.last_name,
        nationality: editData?.cipInfo?.document?.nationality,
        police_record: editData?.cipInfo?.document?.police_record || "N/A",
        kyc_address_kyc: editData?.cipInfo?.kyc?.address,
        applicant_name: editData?.cipInfo?.kyc?.applicant_name,
        country_of_residency: editData?.cipInfo?.kyc?.country_of_residency,
        date_of_birth_kyc: editData?.cipInfo?.kyc?.date_of_birth_kyc,
        nationality_kyc: editData?.cipInfo?.kyc?.nationality,
        postal_code: editData?.cipInfo?.kyc?.postal_code,
      }));

      return;
    }
    console.log(editData?.cipInfo, "cipInfo");
    setfields((p) => ({
      //new fields
      result: editData?.cipInfo?.document?.result || "N/A",
      status: editData?.cipInfo?.document?.status || "N/A",
      document_type: editData?.cipInfo?.document?.document_type || "N/A",
      age_validation: editData?.cipInfo?.document?.age_validation || "N/A",
      data_comparison: editData?.cipInfo?.document?.data_comparison || "N/A",
      data_comparison_breakdown:
        editData?.cipInfo?.document?.data_comparison_breakdown || "N/A",
      image_integrity: editData?.cipInfo?.document?.image_integrity || "N/A",
      // image_integrity_breakdown
      colour_picture:
        editData?.cipInfo?.document?.image_integrity_breakdown
          ?.colour_picture || "N/A",
      conclusive_document_quality:
        editData?.cipInfo?.document?.image_integrity_breakdown
          ?.conclusive_document_quality || "N/A",
      image_quality:
        editData?.cipInfo?.document?.image_integrity_breakdown?.image_quality ||
        "N/A",
      supported_document:
        editData?.cipInfo?.document?.image_integrity_breakdown
          ?.supported_document || "N/A",
      // visual_authenticity
      digital_tampering:
        editData?.cipInfo?.document?.visual_authenticity?.digital_tampering ||
        "N/A",
      face_detection:
        editData?.cipInfo?.document?.visual_authenticity?.face_detection ||
        "N/A",
      fonts: editData?.cipInfo?.document?.visual_authenticity?.fonts || "N/A",
      original_document_present:
        editData?.cipInfo?.document?.visual_authenticity
          ?.original_document_present || "N/A",
      picture_face_integrity:
        editData?.cipInfo?.document?.visual_authenticity
          ?.picture_face_integrity || "N/A",
      security_features:
        editData?.cipInfo?.document?.visual_authenticity?.security_features ||
        "N/A",
      template:
        editData?.cipInfo?.document?.visual_authenticity?.template || "N/A",
      // kyc
      kyc_email_address: editData?.cipInfo?.kyc?.email_address || "N/A",
      kyc_nationality: editData?.cipInfo?.kyc?.nationality || "N/A",
      kyc_id_number: editData?.cipInfo?.kyc?.id_number || "N/A",
      kyc_date_of_birth: editData?.cipInfo?.kyc?.date_of_birth || "N/A",
      kyc_address: editData?.cipInfo?.kyc?.address || "N/A",
      kyc_postal_code: editData?.cipInfo?.kyc?.postal_code || "N/A",
      kyc_kyc_completed_at:
        moment(editData?.cipInfo?.kyc?.kyc_completed_at).format("YYYY-MM-DD") ||
        "N/A",
      kyc_ip_address: editData?.cipInfo?.kyc?.ip_address || "",
      kyc_check_initiated_at:
        moment(editData?.cipInfo?.kyc?.check_initiated_at).format(
          "YYYY-MM-DD"
        ) || "",
      kyc_check_completed_at:
        moment(editData?.cipInfo?.kyc?.check_completed_at).format(
          "YYYY-MM-DD"
        ) || "",
      kyc_approval_status: editData?.cipInfo?.kyc?.approval_status || "N/A",
      kyc_approved_by: editData?.cipInfo?.kyc?.approved_by || "N/A",
      kyc_approved_reason: editData?.cipInfo?.kyc?.approved_reason || "N/A",
      kyc_approved_at:
        moment(editData?.cipInfo?.kyc?.approved_at).format("YYYY-MM-DD") || "",
      kyc_address_kyc: editData?.cipInfo?.kyc?.address_kyc || "N/A",

      // photo
      photo_result: editData?.cipInfo?.photo?.result || "N/A",
      photo_status: editData?.cipInfo?.photo?.status || "N/A",
      photo_face_comparison: editData?.cipInfo?.photo?.face_comparison || "N/A",
      photo_image_integrity: editData?.cipInfo?.photo?.image_integrity || "N/A",
      photo_visual_authenticity:
        editData?.cipInfo?.photo?.visual_authenticity || "N/A",
      //watch list
      watchlist_result: editData?.cipInfo?.watchlist?.result || "N/A",
      watchlist_status: editData?.cipInfo?.watchlist?.status || "N/A",
      watchlist_politically_exposed_person:
        editData?.cipInfo?.watchlist?.politically_exposed_person || "N/A",
      watchlist_sanction: editData?.cipInfo?.watchlist?.sanction || "N/A",
      watchlist_adverse_media:
        editData?.cipInfo?.watchlist?.adverse_media || "N/A",
      watchlist_monitored_lists:
        editData?.cipInfo?.watchlist?.monitored_lists || "N/A",
      // old
      date_of_birth: editData?.cipInfo?.document?.date_of_birth,
      date_of_expiry: editData?.cipInfo?.document?.date_of_expiry,
      document_numbers: editData?.cipInfo?.document?.document_numbers,
      gender: editData?.cipInfo?.document?.gender,
      issuing_country: editData?.cipInfo?.document?.issuing_country,
      last_name: editData?.cipInfo?.document?.last_name,
      nationality: editData?.cipInfo?.document?.nationality,
      police_record: editData?.cipInfo?.document?.police_record || "N/A",
      kyc_address_kyc: editData?.cipInfo?.kyc?.address,
      applicant_name: editData?.cipInfo?.kyc?.applicant_name,
      country_of_residency: editData?.cipInfo?.kyc?.country_of_residency,
      date_of_birth_kyc: editData?.cipInfo?.kyc?.date_of_birth_kyc,
      nationality_kyc: editData?.cipInfo?.kyc?.nationality,
      postal_code: editData?.cipInfo?.kyc?.postal_code,
    }));
  }, [editData]);

  if (
    userProfile?.role !== "ADMIN" &&
    !userPermission?.userCount?.cip_info_view
  ) {
    return null;
  }
  return (
    <>
      <form onSubmit={CommissionParticularFunction}>
        <div className="col-md-12">
          <div
            className="card graph_card border_custom"
            style={{ maxWidth: 1000 }}
          >
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5>Documents Info</h5>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h5>Cip Info</h5>
                  <label className="col-form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleInputName1"
                    name="date_of_birth"
                    max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                    value={fields?.date_of_birth}
                    onChange={handleChange("date_of_birth")}
                  />
                  {validationErrors.date_of_birth && (
                    <div className="text-danger err">
                      {validationErrors.date_of_birth}
                    </div>
                  )}
                  <label className="col-form-label">Date of Expire</label>
                  <input
                    type="date"
                    className="form-control"
                    id="exampleInputName1"
                    name="date_of_expiry"
                    value={fields?.date_of_expiry}
                    onChange={handleChange("date_of_expiry")}
                  />
                  {validationErrors.date_of_expiry && (
                    <div className="text-danger err">
                      {validationErrors.date_of_expiry}
                    </div>
                  )}
                  <label className="col-form-label">Document Numbers</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    name="document_numbers"
                    value={fields?.document_numbers}
                    onChange={handleChange("document_numbers")}
                  />
                  {validationErrors.document_numbers && (
                    <div className="text-danger err">
                      {validationErrors.document_numbers}
                    </div>
                  )}
                  <label className="col-form-label">Gender</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    name="gender"
                    value={fields?.gender}
                    onChange={handleChange("gender")}
                  />
                  {validationErrors.gender && (
                    <div className="text-danger err">
                      {validationErrors.gender}
                    </div>
                  )}
                  <label className="col-form-label">Issuing Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    name="issuing_country"
                    value={fields?.issuing_country}
                    onChange={handleChange("issuing_country")}
                  />
                  {validationErrors.issuing_country && (
                    <div className="text-danger err">
                      {validationErrors.issuing_country}
                    </div>
                  )}
                  <label className="col-form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    name="last_name"
                    value={fields?.last_name}
                    onChange={handleChange("last_name")}
                  />
                  {validationErrors.last_name && (
                    <div className="text-danger err">
                      {validationErrors.last_name}
                    </div>
                  )}
                  <label className="col-form-label">Nationality</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    name="nationality"
                    value={fields?.nationality}
                    onChange={handleChange("nationality")}
                  />
                  {validationErrors.nationality && (
                    <div className="text-danger err">
                      {validationErrors.nationality}
                    </div>
                  )}
                  <label className="col-form-label">Police Record</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    name="police_record"
                    value={fields?.police_record}
                    onChange={handleChange("police_record")}
                  />
                  {validationErrors.police_record && (
                    <div className="text-danger err">
                      {validationErrors.police_record}
                    </div>
                  )}
                  <label className="col-form-label">Result</label>
                  <input
                    type="text"
                    className="form-control"
                    id="result"
                    name="result"
                    value={fields?.result}
                    onChange={handleChange("result")}
                  />
                  {validationErrors.result && (
                    <div className="text-danger err">
                      {validationErrors.result}
                    </div>
                  )}

                  <label className="col-form-label">Status</label>
                  <input
                    type="text"
                    className="form-control"
                    id="status"
                    name="status"
                    value={fields?.status}
                    onChange={handleChange("status")}
                  />
                  {validationErrors.status && (
                    <div className="text-danger err">
                      {validationErrors.status}
                    </div>
                  )}

                  <label className="col-form-label">Document Type</label>
                  <input
                    type="text"
                    className="form-control"
                    id="documentType"
                    name="document_type"
                    value={fields?.document_type}
                    onChange={handleChange("document_type")}
                  />
                  {validationErrors.document_type && (
                    <div className="text-danger err">
                      {validationErrors.document_type}
                    </div>
                  )}

                  <label className="col-form-label">Age Validation</label>
                  <input
                    type="text"
                    className="form-control"
                    id="ageValidation"
                    name="age_validation"
                    value={fields?.age_validation}
                    onChange={handleChange("age_validation")}
                  />
                  {validationErrors.age_validation && (
                    <div className="text-danger err">
                      {validationErrors.age_validation}
                    </div>
                  )}

                  <label className="col-form-label">Data Comparison</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dataComparison"
                    name="data_comparison"
                    value={fields?.data_comparison}
                    onChange={handleChange("data_comparison")}
                  />
                  {validationErrors.data_comparison && (
                    <div className="text-danger err">
                      {validationErrors.data_comparison}
                    </div>
                  )}

                  <label className="col-form-label">
                    Data Comparison Breakdown
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="dataComparisonBreakdown"
                    name="data_comparison_breakdown"
                    value={fields?.data_comparison_breakdown}
                    onChange={handleChange("data_comparison_breakdown")}
                  />
                  {validationErrors.data_comparison_breakdown && (
                    <div className="text-danger err">
                      {validationErrors.data_comparison_breakdown}
                    </div>
                  )}

                  <label className="col-form-label">Image Integrity</label>
                  <input
                    type="text"
                    className="form-control"
                    id="imageIntegrity"
                    name="image_integrity"
                    value={fields?.image_integrity}
                    onChange={handleChange("image_integrity")}
                  />
                  {validationErrors.image_integrity && (
                    <div className="text-danger err">
                      {validationErrors.image_integrity}
                    </div>
                  )}

                  <label className="col-form-label">Colour Picture</label>
                  <input
                    type="text"
                    className="form-control"
                    id="colourPicture"
                    name="colour_picture"
                    value={fields?.colour_picture}
                    onChange={handleChange("colour_picture")}
                  />
                  {validationErrors.colour_picture && (
                    <div className="text-danger err">
                      {validationErrors.colour_picture}
                    </div>
                  )}

                  <label className="col-form-label">
                    Conclusive Document Quality
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="conclusiveDocumentQuality"
                    name="conclusive_document_quality"
                    value={fields?.conclusive_document_quality}
                    onChange={handleChange("conclusive_document_quality")}
                  />
                  {validationErrors.conclusive_document_quality && (
                    <div className="text-danger err">
                      {validationErrors.conclusive_document_quality}
                    </div>
                  )}

                  <label className="col-form-label">Image Quality</label>
                  <input
                    type="text"
                    className="form-control"
                    id="imageQuality"
                    name="image_quality"
                    value={fields?.image_quality}
                    onChange={handleChange("image_quality")}
                  />
                  {validationErrors.image_quality && (
                    <div className="text-danger err">
                      {validationErrors.image_quality}
                    </div>
                  )}

                  <label className="col-form-label">Supported Document</label>
                  <input
                    type="text"
                    className="form-control"
                    id="supportedDocument"
                    name="supported_document"
                    value={fields?.supported_document}
                    onChange={handleChange("supported_document")}
                  />
                  {validationErrors.supported_document && (
                    <div className="text-danger err">
                      {validationErrors.supported_document}
                    </div>
                  )}

                  <label className="col-form-label">Digital Tampering</label>
                  <input
                    type="text"
                    className="form-control"
                    id="digitalTampering"
                    name="digital_tampering"
                    value={fields?.digital_tampering}
                    onChange={handleChange("digital_tampering")}
                  />
                  {validationErrors.digital_tampering && (
                    <div className="text-danger err">
                      {validationErrors.digital_tampering}
                    </div>
                  )}

                  <label className="col-form-label">Face Detection</label>
                  <input
                    type="text"
                    className="form-control"
                    id="faceDetection"
                    name="face_detection"
                    value={fields?.face_detection}
                    onChange={handleChange("face_detection")}
                  />
                  {validationErrors.face_detection && (
                    <div className="text-danger err">
                      {validationErrors.face_detection}
                    </div>
                  )}

                  <label className="col-form-label">Fonts</label>
                  <input
                    type="text"
                    className="form-control"
                    id="fonts"
                    name="fonts"
                    value={fields?.fonts}
                    onChange={handleChange("fonts")}
                  />
                  {validationErrors.fonts && (
                    <div className="text-danger err">
                      {validationErrors.fonts}
                    </div>
                  )}

                  <label className="col-form-label">
                    Original Document Present
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="originalDocumentPresent"
                    name="original_document_present"
                    value={fields?.original_document_present}
                    onChange={handleChange("original_document_present")}
                  />
                  {validationErrors.original_document_present && (
                    <div className="text-danger err">
                      {validationErrors.original_document_present}
                    </div>
                  )}

                  <label className="col-form-label">
                    Picture Face Integrity
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="pictureFaceIntegrity"
                    name="picture_face_integrity"
                    value={fields?.picture_face_integrity}
                    onChange={handleChange("picture_face_integrity")}
                  />
                  {validationErrors.picture_face_integrity && (
                    <div className="text-danger err">
                      {validationErrors.picture_face_integrity}
                    </div>
                  )}

                  <label className="col-form-label">Security Features</label>
                  <input
                    type="text"
                    className="form-control"
                    id="securityFeatures"
                    name="security_features"
                    value={fields?.security_features}
                    onChange={handleChange("security_features")}
                  />
                  {validationErrors.security_features && (
                    <div className="text-danger err">
                      {validationErrors.security_features}
                    </div>
                  )}

                  <label className="col-form-label">Template</label>
                  <input
                    type="text"
                    className="form-control"
                    id="template"
                    name="template"
                    value={fields?.template}
                    onChange={handleChange("template")}
                  />
                  {validationErrors.template && (
                    <div className="text-danger err">
                      {validationErrors.template}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <h5>Photo</h5>

                  <label className="col-form-label">Photo Result</label>
                  <input
                    type="text"
                    className="form-control"
                    id="photoResult"
                    name="photo_result"
                    value={fields?.photo_result}
                    onChange={handleChange("photo_result")}
                  />
                  {validationErrors.photo_result && (
                    <div className="text-danger err">
                      {validationErrors.photo_result}
                    </div>
                  )}

                  <label className="col-form-label">Photo Status</label>
                  <input
                    type="text"
                    className="form-control"
                    id="photoStatus"
                    name="photo_status"
                    value={fields?.photo_status}
                    onChange={handleChange("photo_status")}
                  />
                  {validationErrors.photo_status && (
                    <div className="text-danger err">
                      {validationErrors.photo_status}
                    </div>
                  )}

                  <label className="col-form-label">
                    Photo Face Comparison
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="photoFaceComparison"
                    name="photo_face_comparison"
                    value={fields?.photo_face_comparison}
                    onChange={handleChange("photo_face_comparison")}
                  />
                  {validationErrors.photo_face_comparison && (
                    <div className="text-danger err">
                      {validationErrors.photo_face_comparison}
                    </div>
                  )}

                  <label className="col-form-label">
                    Photo Image Integrity
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="photoImageIntegrity"
                    name="photo_image_integrity"
                    value={fields?.photo_image_integrity}
                    onChange={handleChange("photo_image_integrity")}
                  />
                  {validationErrors.photo_image_integrity && (
                    <div className="text-danger err">
                      {validationErrors.photo_image_integrity}
                    </div>
                  )}

                  <label className="col-form-label">
                    Photo Visual Authenticity
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="photoVisualAuthenticity"
                    name="photo_visual_authenticity"
                    value={fields?.photo_visual_authenticity}
                    onChange={handleChange("photo_visual_authenticity")}
                  />
                  {validationErrors.photo_visual_authenticity && (
                    <div className="text-danger err">
                      {validationErrors.photo_visual_authenticity}
                    </div>
                  )}

                  <h5 className="mt-4">watchlist</h5>

                  <label className="col-form-label">Watchlist Result</label>
                  <input
                    type="text"
                    className="form-control"
                    id="watchlistResult"
                    name="watchlist_result"
                    value={fields?.watchlist_result}
                    onChange={handleChange("watchlist_result")}
                  />
                  {validationErrors.watchlist_result && (
                    <div className="text-danger err">
                      {validationErrors.watchlist_result}
                    </div>
                  )}

                  <label className="col-form-label">Watchlist Status</label>
                  <input
                    type="text"
                    className="form-control"
                    id="watchlistStatus"
                    name="watchlist_status"
                    value={fields?.watchlist_status}
                    onChange={handleChange("watchlist_status")}
                  />
                  {validationErrors.watchlist_status && (
                    <div className="text-danger err">
                      {validationErrors.watchlist_status}
                    </div>
                  )}

                  <label className="col-form-label">
                    Politically Exposed Person
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="watchlistPoliticallyExposedPerson"
                    name="watchlist_politically_exposed_person"
                    value={fields?.watchlist_politically_exposed_person}
                    onChange={handleChange(
                      "watchlist_politically_exposed_person"
                    )}
                  />
                  {validationErrors.watchlist_politically_exposed_person && (
                    <div className="text-danger err">
                      {validationErrors.watchlist_politically_exposed_person}
                    </div>
                  )}

                  <label className="col-form-label">Sanction</label>
                  <input
                    type="text"
                    className="form-control"
                    id="watchlistSanction"
                    name="watchlist_sanction"
                    value={fields?.watchlist_sanction}
                    onChange={handleChange("watchlist_sanction")}
                  />
                  {validationErrors.watchlist_sanction && (
                    <div className="text-danger err">
                      {validationErrors.watchlist_sanction}
                    </div>
                  )}

                  <label className="col-form-label">Adverse Media</label>
                  <input
                    type="text"
                    className="form-control"
                    id="watchlistAdverseMedia"
                    name="watchlist_adverse_media"
                    value={fields?.watchlist_adverse_media}
                    onChange={handleChange("watchlist_adverse_media")}
                  />
                  {validationErrors.watchlist_adverse_media && (
                    <div className="text-danger err">
                      {validationErrors.watchlist_adverse_media}
                    </div>
                  )}

                  <label className="col-form-label">Monitored Lists</label>
                  <input
                    type="text"
                    className="form-control"
                    id="watchlistMonitoredLists"
                    name="watchlist_monitored_lists"
                    value={fields?.watchlist_monitored_lists}
                    onChange={handleChange("watchlist_monitored_lists")}
                  />
                  {validationErrors.watchlist_monitored_lists && (
                    <div className="text-danger err">
                      {validationErrors.watchlist_monitored_lists}
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div className="col-md-6">
                <h5>KYC Info</h5>
                <label className="col-form-label">Address</label>
                <textarea
                  type="date"
                  className="form-control"
                  id="exampleInputName1"
                  name="kyc_address_kyc"
                  value={fields?.kyc_address_kyc}
                  onChange={handleChange("kyc_address_kyc")}
                />
                {validationErrors.kyc_address_kyc && (
                  <div className="text-danger err">
                    {validationErrors.kyc_address_kyc}
                  </div>
                )}
                <label className="col-form-label">Applicant Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  name="applicant_name"
                  value={fields?.applicant_name}
                  onChange={handleChange("applicant_name")}
                />
                {validationErrors.applicant_name && (
                  <div className="text-danger err">
                    {validationErrors.applicant_name}
                  </div>
                )}
                <label className="col-form-label">Country Of Residency</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  name="country_of_residency"
                  value={fields?.country_of_residency}
                  onChange={handleChange("country_of_residency")}
                />
                {validationErrors.country_of_residency && (
                  <div className="text-danger err">
                    {validationErrors.country_of_residency}
                  </div>
                )}
                <label className="col-form-label">Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleInputName1"
                  max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                  name="date_of_birth_kyc"
                  required
                  value={fields?.date_of_birth_kyc}
                  onChange={handleChange("date_of_birth_kyc")}
                />
                {validationErrors.date_of_birth_kyc && (
                  <div className="text-danger err">
                    {validationErrors.date_of_birth_kyc}
                  </div>
                )}
                <label className="col-form-label">Nationality</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  name="nationality_kyc"
                  value={fields?.nationality_kyc}
                  onChange={handleChange("nationality_kyc")}
                />
                {validationErrors.nationality_kyc && (
                  <div className="text-danger err">
                    {validationErrors.nationality_kyc}
                  </div>
                )}
                <label className="col-form-label">Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  name="postal_code"
                  value={fields?.postal_code}
                  onChange={handleChange("postal_code")}
                />
                {validationErrors.postal_code && (
                  <div className="text-danger err">
                    {validationErrors.postal_code}
                  </div>
                )}
                <label className="col-form-label">Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycEmailAddress"
                  name="kyc_email_address"
                  value={fields?.kyc_email_address}
                  onChange={handleChange("kyc_email_address")}
                />
                {validationErrors.kyc_email_address && (
                  <div className="text-danger err">
                    {validationErrors.kyc_email_address}
                  </div>
                )}

                <label className="col-form-label">Nationality</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycNationality"
                  name="kyc_nationality"
                  value={fields?.kyc_nationality}
                  onChange={handleChange("kyc_nationality")}
                />
                {validationErrors.kyc_nationality && (
                  <div className="text-danger err">
                    {validationErrors.kyc_nationality}
                  </div>
                )}

                <label className="col-form-label">ID Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycIdNumber"
                  name="kyc_id_number"
                  value={fields?.kyc_id_number}
                  onChange={handleChange("kyc_id_number")}
                />
                {validationErrors.kyc_id_number && (
                  <div className="text-danger err">
                    {validationErrors.kyc_id_number}
                  </div>
                )}

                <label className="col-form-label">Date of Birth</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycDateOfBirth"
                  name="kyc_date_of_birth"
                  value={fields?.kyc_date_of_birth}
                  onChange={handleChange("kyc_date_of_birth")}
                />
                {validationErrors.kyc_date_of_birth && (
                  <div className="text-danger err">
                    {validationErrors.kyc_date_of_birth}
                  </div>
                )}

                <label className="col-form-label">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycAddress"
                  name="kyc_address"
                  value={fields?.kyc_address}
                  onChange={handleChange("kyc_address")}
                />
                {validationErrors.kyc_address && (
                  <div className="text-danger err">
                    {validationErrors.kyc_address}
                  </div>
                )}

                <label className="col-form-label">Postal Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycPostalCode"
                  name="kyc_postal_code"
                  value={fields?.kyc_postal_code}
                  onChange={handleChange("kyc_postal_code")}
                />
                {validationErrors.kyc_postal_code && (
                  <div className="text-danger err">
                    {validationErrors.kyc_postal_code}
                  </div>
                )}

                <label className="col-form-label">KYC Completed At</label>
                <input
                  type="date"
                  className="form-control"
                  id="kycKycCompletedAt"
                  name="kyc_kyc_completed_at"
                  value={fields?.kyc_kyc_completed_at}
                  onChange={handleChange("kyc_kyc_completed_at")}
                />
                {validationErrors.kyc_kyc_completed_at && (
                  <div className="text-danger err">
                    {validationErrors.kyc_kyc_completed_at}
                  </div>
                )}

                <label className="col-form-label">IP Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycIpAddress"
                  name="kyc_ip_address"
                  value={fields?.kyc_ip_address}
                  onChange={handleChange("kyc_ip_address")}
                />
                {validationErrors.kyc_ip_address && (
                  <div className="text-danger err">
                    {validationErrors.kyc_ip_address}
                  </div>
                )}

                <label className="col-form-label">Check Initiated At</label>
                <input
                  type="date"
                  className="form-control"
                  id="kycCheckInitiatedAt"
                  name="kyc_check_initiated_at"
                  value={fields?.kyc_check_initiated_at}
                  onChange={handleChange("kyc_check_initiated_at")}
                />
                {validationErrors.kyc_check_initiated_at && (
                  <div className="text-danger err">
                    {validationErrors.kyc_check_initiated_at}
                  </div>
                )}

                <label className="col-form-label">Check Completed At</label>
                <input
                  type="date"
                  className="form-control"
                  id="kycCheckCompletedAt"
                  name="kyc_check_completed_at"
                  value={fields?.kyc_check_completed_at}
                  onChange={handleChange("kyc_check_completed_at")}
                />
                {validationErrors.kyc_check_completed_at && (
                  <div className="text-danger err">
                    {validationErrors.kyc_check_completed_at}
                  </div>
                )}

                <label className="col-form-label">Approval Status</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycApprovalStatus"
                  name="kyc_approval_status"
                  value={fields?.kyc_approval_status}
                  onChange={handleChange("kyc_approval_status")}
                />
                {validationErrors.kyc_approval_status && (
                  <div className="text-danger err">
                    {validationErrors.kyc_approval_status}
                  </div>
                )}

                <label className="col-form-label">Approved By</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycApprovedBy"
                  name="kyc_approved_by"
                  value={fields?.kyc_approved_by}
                  onChange={handleChange("kyc_approved_by")}
                />
                {validationErrors.kyc_approved_by && (
                  <div className="text-danger err">
                    {validationErrors.kyc_approved_by}
                  </div>
                )}

                <label className="col-form-label">Approved Reason</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycApprovedReason"
                  name="kyc_approved_reason"
                  value={fields?.kyc_approved_reason}
                  onChange={handleChange("kyc_approved_reason")}
                />
                {validationErrors.kyc_approved_reason && (
                  <div className="text-danger err">
                    {validationErrors.kyc_approved_reason}
                  </div>
                )}

                <label className="col-form-label">Approved At</label>
                <input
                  type="date"
                  className="form-control"
                  id="kycApprovedAt"
                  name="kyc_approved_at"
                  value={fields?.kyc_approved_at}
                  onChange={handleChange("kyc_approved_at")}
                />
                {validationErrors.kyc_approved_at && (
                  <div className="text-danger err">
                    {validationErrors.kyc_approved_at}
                  </div>
                )}

                <label className="col-form-label">Address KYC</label>
                <input
                  type="text"
                  className="form-control"
                  id="kycAddressKyc"
                  name="kyc_address_kyc"
                  value={fields?.kyc_address_kyc}
                  onChange={handleChange("kyc_address_kyc")}
                />
                {validationErrors.kyc_address_kyc && (
                  <div className="text-danger err">
                    {validationErrors.kyc_address_kyc}
                  </div>
                )}
              </div>
              <br />

              <div className="col-md-12">
                {userProfile?.role === "ADMIN" && (
                  <button
                    type="submit"
                    disabled={editData?.applicantData == null ? true : false}
                    className="btn btn-gradient-primary me-2 float-right"
                  >
                    Submit
                  </button>
                )}
                {userProfile?.role === "SUBADMIN" &&
                  userPermission &&
                  userPermission?.userCount?.cip_info_edit && (
                    <button
                      type="submit"
                      disabled={editData?.applicantData == null ? true : false}
                      className="btn btn-gradient-primary me-2 float-right"
                    >
                      Submit
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </form>

      {/* <form onSubmit={CommissionParticularFunction}>
        <div className="d-flex align-items-center justify-content-between">
          <h5>Commission</h5>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Trades</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="tradeCommission"
              value={fields?.tradeCommission}
              min={0}
              step="0.01"
              onChange={handleChange("tradeCommission")}
            />
            {validationErrors.tradeCommission && (
              <div className="text-danger err">
                {validationErrors.tradeCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Deposit</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="depositCommission"
              min={0}
              step="0.01"
              value={fields?.depositCommission}
              onChange={handleChange("depositCommission")}
            />
            {validationErrors.depositCommission && (
              <div className="text-danger err">
                {validationErrors.depositCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Withdrawal</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="withdrawCommission"
              min={0}
              step="0.01"
              value={fields?.withdrawCommission}
              onChange={handleChange("withdrawCommission")}
            />
            {validationErrors.withdrawCommission && (
              <div className="text-danger err">
                {validationErrors.withdrawCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Close</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="sellTradeCommission"
              min={0}
              step="0.01"
              value={fields?.sellTradeCommission}
              onChange={handleChange("sellTradeCommission")}
            />
            {validationErrors.sellTradeCommission && (
              <div className="text-danger err">
                {validationErrors.sellTradeCommission}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label for="exampleInputName1">Fee</label>
            <input
              type="number"
              className="form-control"
              id="exampleInputName1"
              placeholder="$0"
              name="fee"
              min={0}
              step="0.01"
              value={fields?.fee}
              disabled
              onChange={handleChange("fee")}
            />
            {validationErrors.fee && (
              <div className="text-danger err">{validationErrors.fee}</div>
            )}
          </div>
        </div>
        {userRole?.role === "SUBADMIN" &&
        userPermissions.hasOwnProperty("userCount") &&
        userPermissions?.userCount?.update ? (
          <button
            type="submit"
            disabled={show ? true : false}
            className="btn btn-gradient-primary me-2"
          >
            {show ? "Wait.." : "Submit"}
          </button>
        ) : userRole?.role === "ADMIN" ? (
          <button
            type="submit"
            disabled={show ? true : false}
            className="btn btn-gradient-primary me-2"
          >
            {show ? "Wait.." : "Submit"}
          </button>
        ) : (
          <button
            type="submit"
            disabled={true}
            className="btn btn-gradient-primary me-2"
          >
            Submit
          </button>
        )}
      </form> */}
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(OrderDataInfo);
