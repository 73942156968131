import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { debounce } from "lodash";

//imported
import * as Path from "routes/paths";
import { USERS, PAGE_LIMIT } from "helpers/viewContent";
import {
  FORMAT_TEXT,
  FORMAT_TEXT_VALUE,
  DATE_FORMAT_TIME_,
} from "helpers/common";
import ReactPagination from "components/shared/ReactPagination";
import SearchFilter from "components/shared/SearchFilter";

//services
import {
  getList_,
  deleteData_,
  updateData_,
} from "store/services/commonService";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  loader,
  userProfile,
  user,
}) => {
  const history = useHistory();
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  //pagination
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    order: -1,
    orderBy: "createdAt",
  });
  const [listing, setListing] = useState([]);
  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemEmail = list?.email?.toLowerCase();
      return itemName?.includes(searchTerm) || itemEmail?.includes(searchTerm);
    });
  }

  //get userInfo
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        order: +filter?.order,
        url: "getUsersWithFilter",
        search_string: searchValue,
        common: false,
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  const selectSearchOption = [
    {
      id: "1",
      value: -1,
      name: "Newest",
    },
    {
      id: "2",
      value: 1,
      name: "Oldest",
    },
  ];

  const selectSearchOptionStatus = [
    {
      id: "1",
      value: "pending",
      name: "Pending",
    },
    {
      id: "2",
      value: "approved",
      name: "Approved",
    },
  ];

  useEffect(() => {
    let objPer = userData["userCount"];
    setuserSubPermissions(objPer);
  }, [userProfile, userRole]);

  const getStatus = (status) => {
    if (status === "accepted" || status === "approved") {
      return <label className={"badge badge-success"}>{status}</label>;
    } else if (status === "pending") {
      return <label className={"badge badge-pending"}>{status}</label>;
    } else if (status === "closed") {
      return <label className={"badge badge-close"}>{status}</label>;
    } else {
      return <label className={"badge badge-danger"}>{status}</label>;
    }
  };

  //render html
  const renderList_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr
          key={i}
          title="View"
          onClick={() => {
            if (
              userRole === "SUBADMIN" &&
              userSubPermissions !== undefined &&
              userSubPermissions?.update
            ) {
              history.push(`${Path.userview}/${list._id}`);
            } else if (
              userRole === "ADMIN" &&
              userSubPermissions == undefined
            ) {
              history.push(`${Path.userview}/${list._id}`);
            } else {
              console.log("here");
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <td>{list?.first_name ? FORMAT_TEXT(list?.first_name) : "N/A"}</td>
          <td>{list?.last_name ? FORMAT_TEXT(list?.last_name) : "N/A"}</td>
          <td>{list?.email ? FORMAT_TEXT(list?.email) : "N/A"}</td>
          {/* <td>{list?.city ? FORMAT_TEXT(list?.city) : "N/A"}</td> */}
          <td>
            ${" "}
            {list?.openPriceTotal && list?.openPriceTotal > 0
              ? `${list?.openPriceTotal.toFixed(2)}`
              : "0"}
          </td>
          <td>
            ${" "}
            {list?.closePriceTotal && list?.closePriceTotal > 0
              ? `${list?.closePriceTotal.toFixed(2)}`
              : "0"}
          </td>

          <td>${list.walletCredit ? list.walletCredit.toFixed(2) : "0"}</td>
          <td>
            {list?.accountData?.account_number
              ? list?.accountData?.account_number
              : "N/A"}
          </td>
          <td>
            $
            {list.equity != null && !isNaN(list.equity)
              ? parseInt(list.equity).toFixed(2)
              : "0"}
          </td>
          <td>{getStatus(list.status)}</td>
          <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
        </tr>
      ))
    );
  };
  return (
    <>
      <Helmet title={USERS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{USERS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.users}>{USERS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                <SearchFilter
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  clearSerchFilter={clearSerchFilter}
                  options={selectSearchOption}
                  SearchText="Search by name/email"
                  isSearchDate={false}
                  isExportButton={false}
                  isAddButton={false}
                  isInput={true}
                  isSelectBox={true}
                  filter={filter}
                  setFilter={setFilter}
                  dropdownField="order"
                  selectSearchOptionStatus={selectSearchOptionStatus}
                  isSelectStatusBox={true}
                  statusKey="status"
                />
              </h4>
              <div class="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th onClick={() => handleSortingFunc("name")}>
                        {" "}
                        Name{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "name"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "name"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("last_name")}>
                        {" "}
                        Last Name{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "last_name"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "last_name"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("email")}>
                        {" "}
                        Email Id{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "email"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "email"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>

                      {/* <th onClick={() => handleSortingFunc("city")}>
                        {" "}
                        City{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "city"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "city"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th> */}
                      <th onClick={() => handleSortingFunc("openPriceTotal")}>
                        {" "}
                        Open Amount{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "openPriceTotal"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "openPriceTotal"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("closePriceTotal")}>
                        {" "}
                        Close Amount
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "closePriceTotal"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "closePriceTotal"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("walletCredit")}>
                        {" "}
                        Wallet Balance{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "walletCredit"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "walletCredit"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("accountId")}>
                        {" "}
                        Account ID{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "accountId"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "accountId"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th onClick={() => handleSortingFunc("equity")}>
                        {" "}
                        Wallet Equity{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "equity"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "equity"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th>Status</th>
                      <th onClick={() => handleSortingFunc("createdAt")}>
                        {" "}
                        Registration Date{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "createdAt"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "createdAt"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderList_()}</tbody>
                </table>
              </div>
              {listing?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}
              {listing?.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
